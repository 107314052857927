import React from "react"
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import BlackList from '../components/pages/LoSentimosBl'

const BlackListPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    pageContext={pageContext}
    location={location}
    gradient
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <BlackList pageContext={pageContext} />
      </>
    )}
  />
)

export default BlackListPage
