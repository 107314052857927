import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core";
import PropTypes from 'prop-types';



class LoSentimosBl extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosKba({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(LoSentimosBl, 'VDPNLoSentimosBl')
LoSentimosBl.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
LoSentimosBl.defaultProps = {
    companyName: "AFI",
    dataInfo: {
         "textHeader": "Gracias por tu interés en solicitar un préstamo con Apoyo Financiero.",
         "textBox": "Después de verificar la información de tu solicitud y/o historial de préstamos anteriores con Apoyo Financiero, en este momento no podemos continuar con tu solicitud de préstamo.",
         "textBoxTwo": "",
         "textBoxThree": "",
    }
}


